import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"
import ImageGroup from "../components/ui/imageGroup"
import styled from "styled-components"
import FilesImage from "../images/files.svg";
import { File } from 'react-feather';

const DocumentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

const DocumentLink = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0;

  a {
    color: #222222;

    :hover{
      color: #000000;
    }
  }
`;

const HardwarePage = () => (
  <Layout>
    <SEO title="Hardware" />
    <HeroText
      statement="Hardware"
      caption="View some of the documentation for our hardware."
    ></HeroText>

    <ImageGroup image={FilesImage} heading="Files">
      <DocumentContainer>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-Portal-Document.pdf" target="_blank">Android Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-18.pdf" target="_blank">BA-18 Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-22.pdf" target="_blank">BA-22 Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-300T.pdf" target="_blank">BA-300T Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-922.pdf" target="_blank">BA-922 Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-9500.pdf" target="_blank">BA-9500 Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-Multi800.pdf" target="_blank">BA-Multi800 Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/BA-PF-X.pdf" target="_blank">BA-PF-X Biometric Device</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/Full-Height-Turnstile.pdf" target="_blank">Full Height Turnstile</a>
        </DocumentLink>
        <DocumentLink>
          <File size="1rem" color="#d34400" />&nbsp;<a href="/documents/Half-Height-Turnstile.pdf" target="_blank">Half Height Turnstile</a>
        </DocumentLink>
      </DocumentContainer>
    </ImageGroup>

  </Layout>
)

export default HardwarePage
